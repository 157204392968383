<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.keyWord"
            clearable
            placeholder="游戏名称/ID"
            @change="formData.keyWord = formData.keyWord.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="wishListInfo.loading"
      :data="wishListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="sourceId"
        label="sourceID"
        width="150"
      />
      <el-table-column
        prop="gameIdStr"
        label="gameID"
        width="180"
      />
      <el-table-column
        prop="gameName"
        label="游戏名称"
        min-width="300"
      />
      <el-table-column
        prop="addWish"
        min-width="100px"
        label="求上架"
      />
      <el-table-column
        prop="wishCount"
        min-width="100px"
        label="累计加入人数"
      />
      <el-table-column
        prop="cancelWish"
        min-width="100px"
        label="取消人数"
      />
    </el-table>
    <Pagination
      :total="wishListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryWishList(formData)"
    />
  </Layout>
</template>

<script>
import { getGameWishList } from '@/api/wishList'

export default {
  name: 'WishList',
  data () {
    return {
      formData: {
        keyWord: '',
        pageNum: 1,
        pageSize: 20
      },
      wishListInfo: {
        list: [],
        loading: false,
        total: 0
      }
    }
  },
  created () {
    this.queryWishList()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryWishList()
    },
    queryWishList () {
      this.wishListInfo.loading = true
      getGameWishList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.wishListInfo.list = res.data.list
            this.wishListInfo.total = res.data.total
          } else {
            this.$message.error(res.data)
          }
        })
        .finally(() => {
          this.wishListInfo.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
